<template>
  <div>
    <Package-add-new
      :is-add-new-package-sidebar-active.sync="isAddNewPackageSidebarActive"
      @clicked="one"
      :payment-methods="paymentMethods"
      :overdue="membership.overdue"
      :type="'membership'"
    />
    <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
        lg="3"
        class="mb-1 mb-md-0"
      >

      <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
      >
      <b-button size="sm" variant="flat-secondary" block class="mb-1" :to="'/apps/member/edit/'+membership.member_id+'/'">Back</b-button>
      <b-button size="sm" :disable="processing" variant="outline-warning" v-if="membership.overdue" block class="mb-1" @click="settle_overdue()">Settle Overdue - ${{membership.overdue}}</b-button>
      <b-button size="sm" :disable="processing" variant="outline-danger" v-if="membership.overdue" block class="mb-1" @click="manual_settle()">Manual Settle Overdue</b-button>
      <b-form-group
        label="Plan Code"
        label-for="Plan Code"
      >
        <b-form-input
          id="code"
          v-model="membership.code"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Start Date"
        label-for="start-date"
      >
        <flat-pickr
          v-model="membership.start"
          class="form-control"
          :config="{ dateFormat: 'Y-m-d'}"
        />
      </b-form-group>
      <b-form-group
        label="Expire Date"
        label-for="expire-date"
      >
        <flat-pickr
          v-model="membership.end"
          class="form-control"
          :config="{ dateFormat: 'Y-m-d'}"
        />
      </b-form-group>
      <b-form-group
        label="Type"
        label-for="Type"
      >
        <b-form-input
          id="type"
          v-model="membership.type"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        v-if="membership.type == 'DUES'"
        label="Payment Day (For Dues)"
        label-for="Payment Day (For Dues)"
      >
      <b-form-spinbutton
      id="demo-sb"
      v-model="membership.dues_payment_day"
      min="1"
      max="31"
    />
      </b-form-group>
      <b-form-group
        label="Price"
        label-for="Price"
      >
        <b-form-input
          id="price"
          v-model="membership.amount"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Charge After Expire"
        v-if="membership.type == 'DUES'"
        label-for="Status">
        <v-select
          v-model="membership.charge_after_expire"
          :options="YNOptions"
          :reduce="val => val.value"
          :clearable="false"
        />
      </b-form-group>
      <b-form-group
        label="Time Limit Per Day (Minutes)"
        label-for="Price"
      >
        <b-form-input
          id="price"
          v-model="membership.time_limit"
          trim
        />
      </b-form-group>
      <b-form-group
        label="Status"
        label-for="Status">
        <v-select
          v-model="membership.status"
          :options="statusOptions"
          :reduce="val => val.value"
          :clearable="false"
        />
      </b-form-group>


      <b-form-group
        label="Created at"
        label-for="Created at"
      >
        <b-form-input
          id="created_at"
          v-model="membership.created_at"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Remarks"
        label-for="remarks"
      >
        <b-form-textarea
          id="remarks"
          rows="6"
          v-model="membership.note"
          trim
        />


      </b-form-group>
        <b-button size="sm" variant="primary" block class="mb-1" @click="save()" v-if="$can('read', 'staff')">Save</b-button>
    </b-form>
    </b-col>
    <b-col
      cols="12"
      lg="3"
      md="6"
      class="mb-1 mb-md-0 py-2"
    >
    <h5>Free Installment</h5>
    <b-list-group>
   <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(date, index) in membership.free_installment" :key="index" >
     <span>{{date.free_date}}</span>
   </b-list-group-item>
   <b-row class="mt-2">
     <b-col cols="6">
       <v-select v-model="free_installment.year" :options="year" :clearable="false"/>
     </b-col>
     <b-col cols="6">
       <v-select v-model="free_installment.month" :options="month" :clearable="false"/>
     </b-col>
     <b-col cols="12">
        <b-button size="sm" variant="primary" block class="mt-1" @click="add_free_installment()">Add </b-button>
     </b-col>
   </b-row>
 </b-list-group>
 <hr />
 <h5>Freeze Date</h5>
    <b-list-group >
   <b-list-group-item class="d-flex justify-content-between align-items-center"  variant="info" v-for="(date, index) in membership.freeze" :key="index" >
     <span>{{date.start}} to {{ date.end }}</span>
     <b-badge
        variant="danger"
        pill
        class="badge-round"
        style="cursor: pointer;"
        @click="freeze_delete(date.id)"
      >
      <feather-icon
          icon="Trash2Icon"
          size="16"
        />
      </b-badge>
   </b-list-group-item>
   <b-row class="mt-2">
     <b-col cols="12">
      <flat-pickr
          v-model="form_freeze"
          class="form-control"
          :config="{ dateFormat: 'Y-m-d',mode: 'range'}"
        />
     </b-col>
     <b-col cols="12">
        <b-button size="sm" variant="primary" block class="mt-1" @click="add_freeze()">Add </b-button>
     </b-col>
   </b-row>
 </b-list-group>
 <hr />
 <h5>Share Member(s)</h5>
 <b-list-group>
<b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(v, index) in share_member" :key="index" >
  <span>{{v.label}}</span> <font-awesome-icon style="color:red;cursor:pointer;" icon="fa-solid fa-xmark" @click="delete_share_member(index)"/>
</b-list-group-item>
<b-row class="mt-2">

  <b-col cols="12">

    <v-select
      v-model="share_selected_member"
      @search="fetchOptions"
      :options="memberOptions"
      :clearable="false"
      :filterable="false"
      placeholder="Enter Member Code..."
      input-id="Client"
    >
          <template v-slot:spinner="{ loading }">
          <div v-show="loading">Loading...</div>
          </template>
    </v-select>

  </b-col>
  <b-col cols="12">
     <b-button size="sm" variant="primary" block class="mt-1" @click="add_share_member('add')">Add </b-button>
  </b-col>
</b-row>
</b-list-group>


  </b-col>


      <b-col
        cols="12"
        lg="6"
        md="12"
        class="mb-1 mb-md-0 py-2"
      >
    <b-row>
      <b-col
        cols="12"
        md="12"
      >

      <div>
        <iframe style="border:0px;" :src="'//www.cloudfit.pro/'+membership.epath" width="100%" height="600px" type="application/pdf" />
          <div style="text-align:right;"><b-button size="sm" variant="success" @click="send()" class="my-1 px-3" >Send</b-button></div>
         <!-- <b-embed type="embed" v-if="membership.epath" :src="'//www.cloudfit.pro/'+membership.epath" allowfullscreen aspect="1by1"/> -->
        </div>
      </b-col>

    </b-row>
    <!-- table -->
  </b-col>
</b-row>
  </b-card>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BListGroup, BListGroupItem,BTable, BAvatar, BEmbed,BCard, BCardBody, BFormSpinbutton,BBadge, BRow, BCol, BFormSelect, BPagination, BInputGroup,BFormTextarea, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend, BForm, BFormGroup,
} from 'bootstrap-vue'
import PackageAddNew from './SidebarOverdue.vue'
export default {
  components: {
    BCard,
    PackageAddNew,
    BListGroup,
    BListGroupItem,
    ToastificationContent,
    BForm,
    BEmbed,
    BTable,
    flatPickr,
    BAvatar,
    BBadge,
    BFormSpinbutton,
    BRow,
    BFormTextarea,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      memberOptions : [],
      share_member : [],
      share_selected_member : null,
      membership:{
        member_id : 0,
      },
      isAddNewPackageSidebarActive:false,
      year:[],
      month:['01','02','03','04','05','06','07','08','09','10','11','12'],
      free_installment:{
        year:null,
        month:null
      },
      roleOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Staff', value: 'staff' },
      ],
      YNOptions: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Disabled', value: 'DISABLED' },
        { label: 'Freeze', value: 'FREEZE' },
        { label: 'Terminate', value: 'TERMINATE' },
      ],
      centerOptions: [],
      paymentMethods: [],
      processing : false,
      form_freeze : null,
      epath:'',
      isAddNewStaffSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [{ key: 'id', label: '#' }, { key: 'date', label: 'Date' }, { key: 'start', label: 'Start Time' }, { key: 'end', label: 'End Time' }, { key: 'method', label: 'Method' }, { key: 'created_at', label: 'Created at' }, { key: 'is_attend', label: 'Attended' }],
      fields_class: [{ key: 'id', label: '#' }, { key: 'class_date', label: 'Date' }, { key: 'time', label: 'Time' }, { key: 'method', label: 'Method' }, { key: 'created_at', label: 'Created at' }, { key: 'is_attend', label: 'Attended' }],

      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'ACTIVE', 2: 'DISABLED', 3: 'FREEZE', 4: 'TERMINATE', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-danger', 3: 'light-info', 4: 'light-info', 5: 'light-info',
      }],
      selectMode: 'single',
      selected: [],
    }
  },

  created() {
    this.get_data()
    this.get_payment_method()
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.year.push(  currentYear + i);
    }
  },
  methods: {
    send() {
      this.$http.post(process.env.VUE_APP_API_BASE+'/membership/'+ router.currentRoute.params.id +'/send/',{})
        .then(res => {
          this.$toast({
                   component: ToastificationContent,
                   position: 'top-center',
                   props: {
                     title: 'Success',
                     icon: 'CheckIcon',
                     variant: 'success',
                     text: 'Agreement Sent.',
                   },
                 })
        })
    },
    one() {
      console.log("one")
      this.isAddNewPackageSidebarActive = false
      this.get_data()
    },
    get_payment_method() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
        })
    },
    fetchOptions (search, loading) {
      setTimeout(() => {
      this.$http.get(process.env.VUE_APP_API_BASE+'/search/member/?string='+search)
            .then(res => {
              this.memberOptions = res.data
            })
            }, 200);
      },
      add_share_member(action) {
        if (action == 'add') {
          this.share_member.push(this.share_selected_member)
        }
        let v = [];
        this.share_member.forEach((item, i) => {
          v.push(item.value)
        });
        this.share_selected_member = null
        this.$http.post(process.env.VUE_APP_API_BASE+`/membership/`+ router.currentRoute.params.id + `/share_member/add/`,{"share_member":v.toString()})
          .then(res => {
            if (res.data.result === true) {
                 this.$toast({
                   component: ToastificationContent,
                   position: 'top-center',
                   props: {
                     title: 'Success',
                     icon: 'CheckIcon',
                     variant: 'success',
                     text: res.data.message,
                   },
                 })
                 this.get_data()
                 this.processing = false
               } else {
                 this.$toast({
                   component: ToastificationContent,
                   position: 'top-center',
                   props: {
                     title: 'Error',
                     icon: 'XIcon',
                     variant: 'danger',
                     text: res.data.message,
                   },
                 })
                 this.processing = false
               }
          })
      },
      delete_share_member(index) {
        delete this.share_member[index]
        this.add_share_member('delete')
      },
    add_free_installment() {
      if (this.free_installment.year == null || this.free_installment.month == null ) { return }
      this.processing = true
      this.$http.post(process.env.VUE_APP_API_BASE+`/membership/`+ router.currentRoute.params.id + `/free_installment/add/`,this.free_installment)
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },
    freeze_delete( id ) {
      this.$http.post(process.env.VUE_APP_API_BASE+`/membership/`+ router.currentRoute.params.id + `/freeze/delete/`,{ 'id':id })
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },
    add_freeze() {
      if (this.form_freeze == null) { return }
      this.processing = true
      this.$http.post(process.env.VUE_APP_API_BASE+`/membership/`+ router.currentRoute.params.id + `/freeze/add/`,{'date':this.form_freeze})
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },
    settle_overdue() {
      this.processing = true
      this.$http.post(process.env.VUE_APP_API_BASE+`/membership/`+ router.currentRoute.params.id + `/overdue/`,{})
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },
    manual_settle() {
      this.isAddNewPackageSidebarActive=true
      return
      this.processing = true
      this.$http.post(process.env.VUE_APP_API_BASE+`/membership/`+ router.currentRoute.params.id + `/manual_overdue/`,{})
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },
    one() {
      this.isAddNewPackageSidebarActive = false
      this.get_data()
    },
    save() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/membership/`+ router.currentRoute.params.id + `/update/`,this.membership)
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
             }
        })
    },
    get_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/membership/`+ router.currentRoute.params.id + `/`)
        .then(res => {

          this.membership = res.data
          this.share_member = res.data.share_member_id
          //  console.log( res.data.members.length)
        })
    },

    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items
      console.log(items)
    //  this.$router.push(`/apps/staff/edit/${items[0].user_id}/`)
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
